export default function TeachersProfile() {
  return (
    <div>
      <p className="text-xl md:text-2xl lg:text-3xl font-bold py-4 text-gray-600">
        কর্মরত শিক্ষক-কর্মচারীঃ
      </p>
      <div className="grid lg:grid-cols-2 gap-2">
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/headmaster.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">আলহাজ মোঃ শহিদুল ইসলাম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রধান শিক্ষক / অধ্যক্ষ (ভারপ্রাপ্ত)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ , বি.এড</p>
              <p>ঠিকানা : দাইন্যা রামপাল, টাঙ্গাইল সদর</p>
              <p>মোবাইল : ০১৭১৮-১১৯৩৩০</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:shahidul119330@gmail.com"
                  className="text-blue-600">
                  shahidul119330@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/motiar.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোহাম্মদ মতিয়ার রহমান</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহকারী প্রধান শিক্ষক</p>
              <p>শিক্ষাগত যোগ্যতা : এম.কম , বি.এড</p>
              <p>ঠিকানা : মহেলা, কালিহাতি, টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭১৫-৬৬০৭৫৩</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/faruque 2.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">আব্দুল্যা আল ফারুক সিদ্দিকী</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক / জ্যেষ্ঠ প্রভাষক (বাংলা)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ (বাংলা)</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ----</p>
              <p>
                ইমেইল :
                <a href="mailto:" className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/salma.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোসাম্মত সালমা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক / জ্যেষ্ঠ প্রভাষক (ইংরেজী)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ (ইংরেজী)</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭১০-৭৬৬৩৩১</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:salmaakter26690@gmail.com"
                  className="text-blue-600">
                  salmaakter26690@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/kamrul.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ কামরুল আজাদ</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক (ইসলাম শিক্ষা)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : -</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/woman.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">হাসিনা জান্নাত খান</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক (অর্থনীতি)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.এস , এম.এস.এস(অর্থনীতি)</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : -</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/shefali.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">শেফালী আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক (সমাজ কর্ম)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.এস , এম.এস.এস(সমাজকল্যান)</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ বারেক মিয়া</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক (ব্যবস্থাপনা)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.এস , এম.বি.এস(ব্যবস্থাপনা)</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/golam.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ গোলাম মোস্তফা</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : প্রভাষক (হিসাব বিজ্ঞান)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.কম(পাস) , এম.কম</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/shamim.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">শামীম আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (বাংলা)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৬৪-১৭৩৬১১</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:shamimshila124@gmail.com"
                  className="text-blue-600">
                  shamimshila124@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/hashem.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">আবুল হাশেম মিয়া</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (ইংরেজী)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৪৫-৯৯৪৬৯২</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:abulhashem67@gmail.com"
                  className="text-blue-600">
                  abulhashem67@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/parvin.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">পারভীন আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (তথ্য ও যোগাযোগ প্রযুক্তি)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৯৭৬-২২৩৭৪০</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:parvinsohrab123@gmail.com"
                  className="text-blue-600">
                  parvinsohrab123@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/bari.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আঃ বারী ভূইয়া</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (গণিত ও সাধাবি)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.সি , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৩৯-৫৩৫২৩২</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:abdulbari66@gmail.com"
                  className="text-blue-600">
                  abdulbari66@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/man.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ ছালাউদ্দিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (ভৌত বিজ্ঞান)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এস.সি</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৩৫-০৪৮৩৩৮</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:ahmedsalauddin210@gmail.com"
                  className="text-blue-600">
                  ahmedsalauddin210@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/nurul.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মাও. নুরুল আমিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (ধর্ম ও নৈতিক শিক্ষা)</p>
              <p>শিক্ষাগত যোগ্যতা : ফাজিল, কামিল </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭১৯-৬১৯০৩১</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:nurulamin15031967@gmail.com"
                  className="text-blue-600">
                  nurulamin15031967@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/aynal.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আয়নাল হক</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (শারীরিক শিক্ষা)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এ , বি.পি.এড </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৮১৮-৩১৪২৪২</p>
              <p>
                ইমেইল :
                <a href="mailto:ainalhuq15@gmail.com" className="text-blue-600">
                  ainalhuq15@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/nuralam.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ নুরুল আলম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (কৃষি শিক্ষা)</p>
              <p>শিক্ষাগত যোগ্যতা : কৃষি ডিপ্লোমা </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭১৪-৫৯৫২১২</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:nurul595212@gmail.com"
                  className="text-blue-600">
                  nurul595212@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/arifa.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">আরিফা সুলতানা</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (জীব বিজ্ঞান)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এস.সি</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৭২-৪৯১১৩৬</p>
              <p>
                ইমেইল :
                <a href="mailto:jesminbrc@gmail.com" className="text-blue-600">
                  jesminbrc@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/aysha.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">আয়শা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (গ্রন্থাগার ও তথ্য বিজ্ঞান)</p>
              <p>শিক্ষাগত যোগ্যতা : ডিপ্লোমা-ইন-লাইব্রেরি সায়েন্স, বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭২০-০৩১০২৪</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:ayshaakter031024@gmail.com"
                  className="text-blue-600">
                  ayshaakter031024@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/saleha.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">ছালেহা আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (বাংলা)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৩৮-০৮২৫৬৪</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:salahamim12@gmail.com"
                  className="text-blue-600">
                  salahamim12@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/shalim.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোহাম্মদ সেলিম উদ্দিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (ইংরেজী)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.এ , বি.এড </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭১৬-০০০৮৯৩</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:salimuddin725@gmail.com"
                  className="text-blue-600">
                  salimuddin725@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/provati.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">প্রভাতী নাগ</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (গণিত)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.সি , বি.এড</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৩৩-৮৮৪৭৫৬</p>
              <p>
                ইমেইল :
                <a href="mailto:pdjdsen06@gmail.com" className="text-blue-600">
                  pdjdsen06@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/ashraful.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আশরাফুল ইসলাম</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (ব্যবশি)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.বি.এস , এম.বি.এস</p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৬৮০-২২১১১৯</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:ashraf.tangail.ac@gmail.com"
                  className="text-blue-600">
                  ashraf.tangail.ac@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/ruhul.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ রুহুল আমিন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (সামাজিক বিজ্ঞান)</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.এস , এম.এস.এস </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৬৪-৭৮৯৯৬০</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:ruhulamin010293@gmail.com"
                  className="text-blue-600">
                  ruhulamin010293@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/habib.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ হাবিবুর রহমান</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : সহঃ শিক্ষক (খন্ডকালীন)</p>
              <p>শিক্ষাগত যোগ্যতা : এম.বি.এস </p>
              <p>ঠিকানা : কোনাবাড়ি, টাঙ্গাইল</p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/shalim2.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ সেলিম আলমামুন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : অফিস সহকারী কাম কম্পিউটার অপারেটর</p>
              <p>শিক্ষাগত যোগ্যতা : বি.এস.এস (সম্মান) </p>
              <p>ঠিকানা : ধরেরবাড়ী, টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৯৬-৩৪৬০১৯</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:mdshalimalmamun@gmail.com"
                  className="text-blue-600">
                  mdshalimalmamun@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/sonia.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">সোনিয়া আক্তার</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : অফিস সহকারী (খন্ডকালীন)</p>
              <p>শিক্ষাগত যোগ্যতা : এইচ.এস.সি</p>
              <p>ঠিকানা : ধরেরবাড়ী, টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭০৮-৬৫৮৪৩৪</p>
              <p>
                ইমেইল :
                <a
                  href="mailto:soniaakter8434@gmail.com"
                  className="text-blue-600">
                  soniaakter8434@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/ramjan.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ রমজান আলী</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : নিরাপত্তাকর্মী</p>
              <p>শিক্ষাগত যোগ্যতা : এস.এস.সি</p>
              <p>ঠিকানা : ধরেরবাড়ী, টাঙ্গাইল</p>
              <p>মোবাইল : ০১৩১৩-৮৯২০৪৫</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/azad.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আজাদ হোসেন</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : পরিচ্ছন্নতাকর্মী</p>
              <p>শিক্ষাগত যোগ্যতা : ৮ম শ্রেণি পাশ </p>
              <p>ঠিকানা : ধরেরবাড়ী, টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭২১-৬৪২১৪০</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/noisho.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ আনিছুর রহমান</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : নৈশ প্রহরী</p>
              <p>শিক্ষাগত যোগ্যতা : এস.এস.সি </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ০১৭৫৯-০১২৩০১</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/tomalika.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">তমালিকা</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : আয়া</p>
              <p>শিক্ষাগত যোগ্যতা : জে.এস.সি </p>
              <p>ঠিকানা : ধরেরবাড়ী, টাঙ্গাইল</p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white py-2 px-4 flex md:flex-row flex-col gap-4">
          <img
            src="teachers/tarek.jpg"
            className="w-36 shadow-md object-cover h-[180px] md:h-full border mx-auto border-gray-400"
            alt=""
          />
          <div className="border rounded shadow-md p-2 w-full">
            <p className="text-xl font-semibold">মোঃ তারেক</p>
            <div className="bg-gray-300 w-full h-[1px]"></div>
            <div className="text-gray-700">
              <p>পদবী : অফিস সহায়ক</p>
              <p>শিক্ষাগত যোগ্যতা : ৮ম শ্রেণি পাশ </p>
              <p>ঠিকানা : টাঙ্গাইল</p>
              <p>মোবাইল : ---</p>
              <p>
                ইমেইল :
                <a href="mailto: " className="text-blue-600">
                  -
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

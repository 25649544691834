import React from "react";
import Header from "./Pages/Header.jsx";

export default function App() {
  return (
    <div>
      <Header />
    </div>
  );
}

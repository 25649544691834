// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCWek3Nnis7oJI2EAQuP5QhOmij8r2Ceeo",
  authDomain: "dharerbari-school.firebaseapp.com",
  projectId: "dharerbari-school",
  storageBucket: "dharerbari-school.appspot.com",
  messagingSenderId: "331527773179",
  appId: "1:331527773179:web:08143c2b6cad180169c95e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const storage = getStorage(app);

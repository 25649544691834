import { useState, useEffect } from "react";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "../firebase";
import { v4 } from "uuid";
import Swal from "sweetalert2";

function Rut() {
  const [RutUpoad, setRutUpoad] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    // Fetch and set the file names when the component mounts
  }, []);
  const imagesListRef = ref(storage, "images/");
  const uploadFile = () => {
    if (RutUpoad == null) return;
    const imageRef = ref(storage, `Rutins/${RutUpoad.name + v4()}`);
    uploadBytes(imageRef, RutUpoad).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        Swal.fire("ক্লাস রুটিন সফলভাবে আপলোড হয়েছে!!");
      });
    });
  };
  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  return (
    <div className="flex flex-col gap-6 mx-auto">
      <input
        className="shadow-md p-6"
        type="file"
        onChange={(event) => {
          setRutUpoad(event.target.files[0]);
        }}
      />
      <button
        className="uppercase font-bold font-[arial] bg-violet-900 hover:bg-violet-700 py-1 px-6 text-white rounded"
        onClick={uploadFile}>
        {" "}
        Upload Rutin
      </button>
    </div>
  );
}

export default Rut;

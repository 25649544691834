import React from "react";
import SignIn from "./../components/auth/SignIn";
import SignUp from "./../components/auth/SignUp";

const LogIn = () => {
  return (
    <div>
      <div className="px-1 md:px-[100px] lg:px-[300px]">
        <SignIn />
      </div>
      {/* <SignUp /> */}
    </div>
  );
};

export default LogIn;
